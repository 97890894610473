import React, {useState, useEffect} from "react"
import "../styles/styles.css"
import {HexColorPicker} from 'react-colorful'
import randomColor from "randomcolor"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const letters = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const dotw = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

let overallN = 0;
let stimBucket = [];
let finalBucket = [];

let results = [];

// markup
const SynTestPage = () => {
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    return renderPage(pageIndex);
  }, [pageIndex])

  function renderPage(page) {
    switch (page) {
      case 0:
        return <StartPage setPageIndex={setPageIndex}/>
      case 1:
        return <TestPage setPageIndex={setPageIndex}/>
      case 2:
        return <ResultPage setPageIndex={setPageIndex}/>
      default:
        return <StartPage setPageIndex={setPageIndex}/>
    }
  }

  return (
    renderPage(pageIndex)
  );
}

const StartPage = ({setPageIndex}) => {
  const [letterActive, setLetterActive] = useState(0);
  const [numberActive, setNumberActive] = useState(0);
  const [dotwActive, setDotwActive] = useState(0);
  const [monthActive, setMonthActive] = useState(0);
  const [display, setDisplay] = useState("none");

  useEffect(() => {
    if(letterActive || numberActive || dotwActive || monthActive){
      setDisplay("block");
    } else {
      setDisplay("none");
    }
  }, [letterActive, numberActive, dotwActive,monthActive]);

  function startClicked() {
    makeBucket();
    setPageIndex(1);
  }

  function makeBucket() {
    stimBucket = [];
    finalBucket = [];
    results = [];
    overallN = 0;

    if(letterActive) {
      stimBucket.push(...letters);
      overallN += letters.length;
    }
    if(numberActive) {
      stimBucket.push(...numbers);
      overallN += numbers.length;
    }
    if(dotwActive) {
      stimBucket.push(...dotw);
      overallN += dotw.length;
    }
    if(monthActive) {
      stimBucket.push(...months);
      overallN += months.length;
    }

    finalBucket.push(...shuffle(stimBucket));
    finalBucket.push(...shuffle(stimBucket));
    finalBucket.push(...shuffle(stimBucket));
  }

  function shuffle(array) {
    let currentIndex = array.length;
    let randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  return (
    <main className="background">
      <title>SynTest</title>
      <AppJumbotron title={"Select Stimuli"}/>
      <div className="stimuli">
        <StimulusButton title="Letters" setStimActive={setLetterActive}/>
        <StimulusButton title="Numbers" setStimActive={setNumberActive}/>
        <StimulusButton title="Days of the Week" setStimActive={setDotwActive}/>
        <StimulusButton title="Months" setStimActive={setMonthActive}/>
      </div>
      <div id="startButton">
        <button onClick={() => startClicked()} style={{"display": display}}>Start Test</button>
      </div>
    </main>
  );
}

const StimulusButton = ({title, setStimActive}) => {
  const [active, toggleActive] = useState(false);
  const [color, setColor] = useState("cornflowerblue");

  useEffect(() => {
    active ? setColor("green") : setColor("cornflowerblue");
    setStimActive(active);
  }, [active]);

  return (
    <button style={{"backgroundColor": color}} className="stimulus" onClick={() => toggleActive(active => !active)}>
      {title}
    </button>
  );
}

const AppJumbotron = ({title}) => {
  return (
    <div className="jumbotron">
        <h2>{title}</h2>
    </div>
  );
}

const TestPage = ({setPageIndex}) => {
  const [text, setText] = useState(finalBucket[0]);
  const [color, setColor] = useState("#FFFFFF");
  const [count, setCount] = useState(0);
  const [buttonText, setButtonText] = useState("Next");

  function next() {
    saveColor();

    if(count < finalBucket.length) {
      setCount(prevCount => prevCount + 1);
    }
  }

  useEffect(() => {
    if(count < finalBucket.length) {
      setText(finalBucket[count]);
      let color = randomColor();
      setColor(randomStartColor(color));

      if(count === finalBucket.length - 1) {
        setButtonText("Finish");
      }
    } else if(count === finalBucket.length) {
      setPageIndex(2);
    }
  }, [count]);

  function randomStartColor(color) {
    let locF = Math.floor(Math.random() * 3) + 1;
    let loc0 = Math.floor(Math.random() * 3) + 1;

    while(locF === loc0) {
      locF = Math.floor(Math.random() * 3) + 1;
      loc0 = Math.floor(Math.random() * 3) + 1;
    }

    if(locF === 1) {
      color = "#" + "ff" + color.substr(3);
    } else if(locF === 2) {
      color = "#" + color.substr(1,2) + "ff" + color.substr(5);
    } else {
      color = "#" + color.substr(1,4) + "ff";
    }

    if(loc0 === 1) {
      color = "#" + "00" + color.substr(3);
    } else if(loc0 === 2) {
      color = "#" + color.substr(1,2) + "00" + color.substr(5);
    } else {
      color = "#" + color.substr(1,4) + "00";
    }

    return color;
  }

  function saveColor() {
    let index = 0;

    for(const group of results) {
      if(group[0] === finalBucket[count]) {
        results[index].push(color);
        break;
      } else {
        index++;
      }
    }

    if(index === results.length) {
      results.push([finalBucket[count]]);
      results[index].push(color);
    }

    /*if(results[finalBucket[count]]) {
      results[finalBucket[count]].push(color);
    } else {
      results[finalBucket[count]] = [color];
    }*/
  }

  return (
    <main>
      <title>SynTest</title>
      <AppJumbotron title={"Choose the color you associate with each stimulus"}/>
      <div className="container">
        <HexColorPicker onChange={setColor} color={color} style={{float: "left"}}/>
        <div className="stimArea">
          <div className="stimDiv">
            <h1 className="stimulusText" style={{color: "white"}}>{text}</h1>
          </div>
          <div className="color-box" style={{backgroundColor: color}}/>
        </div>
      </div>
      <div style={{display: "flex", margin: "10px auto"}}>
        <button id="nextButton" onClick={next} style={{padding: "10px 20px"}}>{buttonText}</button>
      </div>
    </main>
  );
}

const ResultTR = ({group, score}) => {
  return (
    <tr style={{width: "50%", margin: "0 auto", textAlign: "center", border: "solid", borderColor: "black", borderWidth: "3px", userSelect: "none"}}>
      <td style={{backgroundColor: "white", width: "25%", border: "solid", borderWidth: "2px", borderColor: "black", borderCollapse: "collapse",fontFamily: "Arial, Helvetica, sans-serif"}}>{group[0]}</td>
      <td style={{backgroundColor: group[1], width: "25%", border: "solid", borderWidth: "2px", borderColor: "black", borderCollapse: "collapse"}}></td>
      <td style={{backgroundColor: group[2], width: "25%", border: "solid", borderWidth: "2px", borderColor: "black", borderCollapse: "collapse"}}></td>
      <td style={{backgroundColor: group[3], width: "25%", border: "solid", borderWidth: "2px", borderColor: "black", borderCollapse: "collapse"}}></td>
      <td style={{backgroundColor: "white", width: "25%", border: "solid", borderWidth: "2px", borderColor: "black", borderCollapse: "collapse",fontFamily: "Arial, Helvetica, sans-serif"}}>{Math.round((score + Number.EPSILON) * 100) / 100}</td>
    </tr>
  );
}

const ResultPage = ({setPageIndex}) => {
  const [score, setScore] = useState(calculateScore());


  function hexToRgbA(hex){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return {r: ((c>>16)&255)/255, g: ((c>>8)&255)/255, b: ((c&255))/255};
        //return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    }
    throw new Error('Bad Hex');
  }

  function calculateScore() {
    let overallSum = 0;

    for(const group in results) {
      let rgbaValues = [];

      for(const item of results[group]) {
        if(item.substring(0, 1) === "#")
          rgbaValues.push(hexToRgbA(item));
      }
      
      overallSum += Math.abs(rgbaValues[0].r - rgbaValues[1].r);
      overallSum += Math.abs(rgbaValues[0].g - rgbaValues[1].g);
      overallSum += Math.abs(rgbaValues[0].b - rgbaValues[1].b);

      overallSum += Math.abs(rgbaValues[0].r - rgbaValues[2].r);
      overallSum += Math.abs(rgbaValues[0].g - rgbaValues[2].g);
      overallSum += Math.abs(rgbaValues[0].b - rgbaValues[2].b);

      overallSum += Math.abs(rgbaValues[1].r - rgbaValues[2].r);
      overallSum += Math.abs(rgbaValues[1].g - rgbaValues[2].g);
      overallSum += Math.abs(rgbaValues[1].b - rgbaValues[2].b);
    }

    return overallSum / overallN;
  }

  function calculateGroupScore(group) {
    let rgbaValues = [];
    let sum = 0;

    for(const item of group) {
      if(item.substring(0, 1) === "#")
        rgbaValues.push(hexToRgbA(item));
    }
    
    sum += Math.abs(rgbaValues[0].r - rgbaValues[1].r);
    sum += Math.abs(rgbaValues[0].g - rgbaValues[1].g);
    sum += Math.abs(rgbaValues[0].b - rgbaValues[1].b);

    sum += Math.abs(rgbaValues[0].r - rgbaValues[2].r);
    sum += Math.abs(rgbaValues[0].g - rgbaValues[2].g);
    sum += Math.abs(rgbaValues[0].b - rgbaValues[2].b);

    sum += Math.abs(rgbaValues[1].r - rgbaValues[2].r);
    sum += Math.abs(rgbaValues[1].g - rgbaValues[2].g);
    sum += Math.abs(rgbaValues[1].b - rgbaValues[2].b);

    return sum/3;
  }

  function reset() {
    setPageIndex(0);
  }

  return (
    <main className="background" onLoad={calculateScore}>
      <title>SynTest</title>
      <AppJumbotron title={"Results"}/>
      <div className="jumbotron" style={{height: 'auto'}}>
        Score: {score}
      </div>
      <div style={{margin: "10px"}}>
        <table style={{maxWidth: "500px", width: "100%", margin: "0px auto", border: "solid", borderWidth: "2px", borderColor: "black", borderCollapse: "collapse"}}>
          <tbody>
            {
              results.map(group => {
                return (
                  <ResultTR group={group} score={calculateGroupScore(group)}/>
                );
              })
            }
          </tbody>
        </table>
      </div>
      <div style={{display: "flex", margin: "0 auto"}}>
        <button id="resetButton" onClick={reset}>Reset</button>
      </div>
    </main>
  )
}

export default SynTestPage
